<template>
  <div class="page-home">
    <div class="feature bg-home shadow-bottom">
      <h4 class="text-center text-spaced text-secondary fw-bold mb-4">
        ROBOTIC ACADEMY
      </h4>
      <h1 class="feature-lead feature-lead-fixed">
        Develop Digital<br />Talents
      </h1>
      <div class="pt-5">
        <router-link
          class="
            btn
            bg-blue
            text-light text-start
            lh-sm
            px-4
            py-3
            d-flex
            justify-content-between
            align-items-center
          "
          :to="{ name: 'Classes' }"
        >
          <span>Claim free trial class</span>
          <i class="fas fa-caret-right text-light ms-4" />
        </router-link>
      </div>
    </div>
    <div>
      <div class="bg-primary number-pill row mx-auto g-4 shadow">
        <div
          v-for="(number, index) in numbers"
          :key="`number${index}`"
          class="col-4 mb-4"
        >
          <p class="text-light">{{ number.title }}</p>
          <h1 class="text-warning" v-if="!loadingNumbers">
            {{ number.number }}
          </h1>
          <div
            class="spinner-border text-warning mt-3"
            role="status"
            v-else
          ></div>
        </div>
      </div>
      <div class="feature-section bg-light section-courses">
        <div class="container mx-auto">
          <p class="lead text-center text-muted mt-4">
            Specially curated modules to provide your child with<br />coding,
            robotic &amp; technology courses
          </p>
          <div class="row mx-auto courses-logo">
            <div
              class="
                col-2
                py-4
                d-flex
                justify-content-center
                align-items-center
                mx-auto
              "
              style="max-width: fit-content"
              v-for="(course, index) in courses"
              :key="`course${index}`"
            >
              <img :src="course" class="image-fit" />
            </div>
          </div>
          <div
            class="w-100 overflow-hidden mt-4"
            v-if="featuredVideos.length > 0"
          >
            <p class="lead text-center fw-bold">Featured Webinars</p>
            <section class="splide">
              <div class="splide__track">
                <ul class="splide__list">
                  <li
                    class="splide__slide"
                    v-for="video in featuredVideos"
                    :key="`featuredVideo-${video._id}`"
                    @click="selectVideo(video)"
                  >
                    <img :src="video.thumbnail.url" />
                  </li>
                </ul>
              </div>
              <div class="splide__arrows"></div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="feature-section container mx-auto">
      <div
        class="
          badge
          bg-purple
          rounded-pill
          text-start
          px-5
          py-4
          mt-5
          shadow
          text-wrap
        "
      >
        <p class="lead fw-medium mb-0">YOUR KIDS JOURNEY</p>
        <h3 class="text-primary mb-0">Towards IR4.0 SKILLS EXPERT</h3>
      </div>
      <img src="@/assets/images/journey.jpg" class="w-100 h-auto" />
      <div class="pt-5 text-center mb-5">
        <router-link
          :to="{ name: 'Classes' }"
          class="btn btn-dark shadow px-4 py-3"
        >
          Start their journey now
          <i class="fas fa-caret-right text-light ms-4" />
        </router-link>
      </div>
    </div>
    <div class="feature-section shadow-top bg-cement">
      <h3 class="fw-medium text-center mb-5">STEM EDUCATION</h3>
      <p class="text-center">
        Ministry of Education Malaysia (KPM) had embarked into
        <i>Pembelajaran Abad Ke-21 (PAK21)</i> that one of the focus is
        <span class="fw-medium">STEM</span> Education
      </p>
      <p class="text-center">
        STEM stands for
        <span class="fw-medium">
          SCIENCE, TECHNOLOGY, ENGINEERING &amp; MATHEMATICS
        </span>
      </p>
      <p class="mb-0 text-center">
        STEM initiative in Malaysia Education Blueprint 2013 - 2025 aims to
        <span class="fw-medium">
          prepare students with the skills to meet the science and technology
          challenges
        </span>
        and to
        <span class="fw-medium">
          ensure that Malaysia has a sufficient number of qualified STEM
          graduates
        </span>
      </p>
    </div>
    <div class="feature-section bg-light">
      <div class="container mx-auto">
        <h3 class="fw-medium text-center mb-5">
          <span
            class="badge rounded-pill bg-warning text-dark px-5 py-4 shadow"
          >
            <span class="text-primary">JAZARIAN</span> Benefits
          </span>
        </h3>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div
            class="col"
            v-for="(benefit, index) in benefits"
            :key="`benefit${index}`"
          >
            <div
              class="card px-4 pt-5 pb-3 text-center h-100"
              :class="benefit.background"
            >
              <img :src="benefit.icon" class="card-img-top card-icon mb-4" />
              <div class="card-body">
                <p class="card-title text-dark mb-4 fw-medium">
                  {{ benefit.title }}
                </p>
                <p class="card-text mb-0 small">
                  {{ benefit.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 text-center">
          <router-link
            class="
              btn
              bg-purple-dark
              text-light
              lh-sm
              px-4
              py-3
              text-start
              d-flex
              justify-content-between
              align-items-center
              mx-auto
              text-nowrap
            "
            style="width: fit-content"
            :to="{ name: 'Classes' }"
          >
            Awesome benefits!<br />I want my free trial class
            <i class="fas fa-caret-right text-light fa-2x ms-4" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="feature-section bg-cement">
      <h3 class="text-center">OUR PARTNERS</h3>
      <div
        class="row mx-auto courses-logo"
        v-for="(partnerRow, partnerIndex) in partners"
        :key="`partnerRow${partnerIndex}`"
      >
        <div
          class="py-4 d-flex justify-content-center align-items-center mx-auto"
          :class="`col-${Math.ceil(12 / partnerRow.length)}`"
          style="max-width: fit-content; max-width: 200px"
          v-for="(partner, index) in partnerRow"
          :key="`partner${index}`"
        >
          <img :src="partner" class="image-fit" />
        </div>
      </div>
    </div>
    <media-viewer
      v-if="currentVideo && currentVideo.url"
      :url="currentVideo.url"
      :mimeType="currentVideo.type"
      @close="currentVideo = null"
    />
  </div>
</template>

<script>
import Splide from "@splidejs/splide";
import MediaViewer from "@/components/Modals/MediaViewer";

export default {
  components: { MediaViewer },
  data() {
    return {
      courses: [
        "https://jazro.sgp1.digitaloceanspaces.com/mbot_ff1abbbf00.png",
        "https://jazro.sgp1.digitaloceanspaces.com/gamemaking_e1b8fba666.png",
        "https://jazro.sgp1.digitaloceanspaces.com/iotproject_f24ca96379.png",
        "https://jazro.sgp1.digitaloceanspaces.com/autism_6412c86286.png",
        "https://jazro.sgp1.digitaloceanspaces.com/kittlejazari_816f285f8c.png",
        "https://jazro.sgp1.digitaloceanspaces.com/makex_a7f6e21698.png",
      ],
      partners: [
        [
          "https://jazro.sgp1.digitaloceanspaces.com/image_3927da6cf4.png",
          "https://jazro.sgp1.digitaloceanspaces.com/image_6e68d7d7a7.png",
          "https://jazro.sgp1.digitaloceanspaces.com/image_e5b82725d1.png",
          "https://jazro.sgp1.digitaloceanspaces.com/image_2df0a74f2b.png",
        ],
        [
          "https://jazro.sgp1.digitaloceanspaces.com/image_caa01b5819.png",
          "https://jazro.sgp1.digitaloceanspaces.com/TBSB_logo_85x_2x_d5cabdaad1.png",
          "https://jazro.sgp1.digitaloceanspaces.com/small_image_ae924e1673.png?1622732",
          "https://jazro.sgp1.digitaloceanspaces.com/image_220fe3de78.png",
        ],
      ],
      numbers: [
        {
          name: "teachers",
          title: "TEACHERS JOIN",
          number: 47,
        },
        {
          name: "jazarians",
          title: "JAZARIANS JOINED",
          number: 1918,
        },
        {
          name: "programs",
          title: "TOTAL PROGRAMS",
          number: 79,
        },
      ],
      benefits: [
        {
          icon: require("@/assets/icons/benefit-1.png"),
          title: "STEM EDUCATION & ROBOTIC CLASS LIKE NO OTHERS",
          background: "bg-teal",
          description:
            "Our event and classes are curated for beginners & higher level with guidance and advice from higher institute lecturers & experts with more than 20 years’ experience  in STEM related education",
        },
        {
          icon: require("@/assets/icons/benefit-2.png"),
          title: "CERTIFICATION FROM RECOGNIZED BODY IN MALAYSIA",
          background: "bg-purple",
          description:
            "Get certified by a Professional Engineer (accredited by Board of Engineers  Malaysia) & MDEC #mydigitalmaker Official Digital Maker Hub (DMH) in Malaysia",
        },
        {
          icon: require("@/assets/icons/benefit-3.png"),
          title: "INDIVIDUAL PERFORMANCE REPORT DASHBOARD",
          background: "bg-cyan",
          description:
            "Get individual performance through Jazarian Performance Report. They will be assessed via Quarterly Assessment to gauge their understanding",
        },
        {
          icon: require("@/assets/icons/benefit-4.png"),
          title: "LEARN FROM THE BEST",
          background: "bg-yellow",
          description:
            "Our Certified Robotic Trainers will give the BEST learning experience to all Jazarians. They are among the experts to train other teachers in public and private institutions",
        },
        {
          icon: require("@/assets/icons/benefit-5.png"),
          title: "INDUSTRY 4WRD CAREER",
          background: "bg-blue",
          description:
            "Clear career path towards future INDUSTRY 4WRD. Start exploring IR4.0 knowledge to catapult your children interest towards technology",
        },
        {
          icon: require("@/assets/icons/benefit-6.png"),
          title: "ONLINE COURSE",
          background: "bg-orange",
          description:
            "Access to our comprehensive Technology Course in our web portal & learn with your own-pace. We provide online class with our Certified Robotic Tutors to help our Jazarian during online class",
        },
      ],
      featuredVideos: [],
      currentVideo: null,
      loadingNumbers: false,
    };
  },
  methods: {
    selectVideo(video) {
      this.currentVideo = {
        url: null,
        type: null,
      };

      if (video.video_type == "Upload") {
        this.currentVideo.url = this.Helper.formatMediaUrl(video);

        this.currentVideo.type = this.Helper.getMediaMimeType(video);
      } else if (video.video_type == "YouTube") {
        this.currentVideo.url = `https://www.youtube.com/embed/${video.id_or_link}`;

        this.currentVideo.type = "youtube";
      } else {
        this.currentVideo.url = video.id_or_link;

        this.currentVideo.type = "video";
      }
    },
    async getFeaturedVideos() {
      this.API.get("featured-videos")
        .then((retVal) => {
          if (retVal.data && retVal.data.length > 0) {
            let videos = retVal.data.filter(
              (data) => data.thumbnail && data.thumbnail.url
            );

            this.featuredVideos = videos;
          }

          if (this.featuredVideos.length > 0) {
            this.$nextTick(() => {
              this.mountSplide();
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getGeneralInfos() {
      this.loadingNumbers = true;

      this.API.get("general-info")
        .then((retVal) => {
          if (retVal.data) {
            this.numbers.forEach((item) => {
              if (item.name == "teachers") {
                return (item.number = retVal.data.totalTeacher);
              } else if (item.name == "jazarians") {
                return (item.number = retVal.data.totalJazarian);
              } else if (item.name == "programs") {
                return (item.number = retVal.data.totalProgram);
              }
            });
          }

          this.loadingNumbers = false;
        })
        .catch((err) => {
          console.error(err);

          this.loadingNumbers = false;
        });
    },
    mountSplide() {
      var splide = new Splide(".splide", {
        drag: "free",
        snap: true,
        fixedWidth: 231,
        height: 130,
        gap: 10,
        rewind: true,
        focus: "left",
        pagination: false,
        arrows: true,
        classes: {
          arrow: "splide__arrow splide__arrow--custom",
        },
      });

      splide.mount();
    },
  },
  mounted() {
    this.getFeaturedVideos();

    this.getGeneralInfos();
  },
};
</script>

<style lang="scss" scoped>
@import "~@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

.splide__slide {
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>